import React from 'react'
import SelectableItem from '../../generic/SelectableItem'
import { printCurrency } from '../../../lib/utils'

function getCoveredSubText(selected, estimates, type) {
  const helperText = {
    spouse: "Add your spouse to your cover.",
    children: "Add cover for up to 5 children under 18.",
    parents: "Add cover for up to 4 parents or in-laws.",
    extended: "Add cover for up to 10 extended family members."
  }

  return <>
    {helperText[type]}<br/>
    {(!selected && estimates && estimates[type] && `Monthly Contribution impact from: ${printCurrency(estimates[type])}`) || ""}
  </>
}

function WhoToCover({ chatState, setChatState, gotoNext, isQuote, addOns }) {
  const { spouse, children, parents, extended, ageSpouse } = chatState

  return <div style={{ width: "100%"}}>
    <SelectableItem control="radio" selected={true} title="Myself" />
    <SelectableItem control="radio" selected={spouse || false} title="My Spouse" onChange={() => { if (spouse || (!spouse && ageSpouse)) { setChatState({ spouse: !spouse }); } if (!ageSpouse && isQuote) { gotoNext(["spouseInfo"]) } } } subtext={getCoveredSubText(spouse, addOns, 'spouse')} />
    <SelectableItem control="radio" selected={children || false} title="My Children" onChange={() => setChatState({ children: !children })} subtext={getCoveredSubText(children, addOns, 'children')} />
    <SelectableItem control="radio" selected={parents || false} title="My Parents" onChange={() => { if (parents) { setChatState({ parents: false }); } if (isQuote && !parents) { gotoNext(["parentQuoteInfo"]) } }} subtext={getCoveredSubText(parents, addOns, 'parents')} />
    <SelectableItem control="radio" selected={extended || false} title="My Extended Family" onChange={() => { if (extended) { setChatState({ extended: false }); } if (isQuote && !extended) { gotoNext(["extendedQuoteInfo"]) } }} subtext={getCoveredSubText(extended, addOns, 'extended')} />
    <p />
  </div>
}


export default WhoToCover
