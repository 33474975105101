import React, { useState, useEffect } from 'react'
import { isRequired, isValidRsaIdNumber, isValidCellphoneNumber, isValidEmail, isValidAge, doesDOBMatchIdNumber, isValidIdNumber, isRequiredForThirdPartyAccount, isNumber, idNumberAgeCheck, getAgeFromID } from '../../../lib/validators'
import WizardModal from '../../shared/WizardModal'
import firebase from 'gatsby-plugin-firebase'
import gql from 'graphql-tag'
import { FuneralQuickQuoteHeader, FuneralQuickQuoteProgress, QuickQuoteModalContainer } from '../../funeral/FuneralQuickQuote/FuneralQuickQuote'
import { useQuery } from 'react-apollo'
import { banks } from '../../../constants'
import { validate } from '@material-ui/pickers'
import { navigate } from 'gatsby'
import { GET_GROUPS } from '../GroupsList/GroupsList'

const MY_DETAILS = gql`{
  me {
    id
  }
}`

const CREATE_CLIENT = gql`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
      firstNames
      surname
      idNumber
      email
      cellNumbers
      birthDate
    }
  } 
`

const CREATE_GROUP = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
    }
  } 
`

const UPDATE_GROUP = gql`
  mutation UpdateGroup($id: String!, $input: UpdateGroupInput!) {
    updateGroup(id: $id, input: $input) {
      id
    }
  } 
`

const CREATE_GROUP_PRODUCT = gql`
  mutation CreateGroupProduct($input: CreateGroupProductInput!) {
    createGroupProduct(input: $input) {
      id
      groupProductNumber
    }
  } 
`

const UPDATE_GROUP_PRODUCT = gql`
  mutation UpdateGroupProduct($id: String!, $input: CreateGroupProductInput!) {
    updateGroupProduct(id: $id, input: $input) {
      id
      groupProductNumber
    }
  } 
`

const CREATE_BANK_ACCOUNT = gql`
  mutation CreateBankAccount($input: CreateBankAccountInput!) {
    createBankAccount(input: $input) {
      id
    }
  }
`

// TODO: Field Names based on chatstate
const buildSteps = (setOpen) => ([{
  name: "start",
  nextAfterDelay: 10,
  noNext: true,
  nextSteps: ["groupDetails"]
}, {
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("steps", [
      { text: "Group Details" },
      { text: "Role Holder 1" },
      { text: "Role Holder 2" },
      { text: "Role Holder 3" },
      { text: "Bank Details" },
      //{ text: "Funeral Product Details" }
    ])
    changeFieldValue("wizardSection", "Buy")
    changeFieldValue("currentStep", 0)
  },
  name: "groupDetails",
  title: "Create a New Group",
  title2: "Group Details",
  fieldNames: ["name", "postalCode"],
  nextSteps: ["createGroup"]
}, {
  name: "createGroup",
  beforeStep: async ({ changeFieldValue, chatState, apolloClient }) => {
    const { name, postalCode, salesPersonId } = chatState
    const result = await apolloClient.mutate({ mutation: CREATE_GROUP, variables: { input: { name, postalCode, consultantId: salesPersonId } } })
    changeFieldValue("groupId", result.data.createGroup.id)
    const result2 = await apolloClient.mutate({ mutation: CREATE_GROUP_PRODUCT, variables: { input: { groupId: result.data.createGroup.id, productType: "GROUP_FUNERAL_BSSP" } }, refetchQueries: [{ query: GET_GROUPS }] })
    changeFieldValue("groupProductId", result2.data.createGroupProduct.id)
    changeFieldValue("groupProductNumber", result2.data.createGroupProduct.groupProductNumber)
    changeFieldValue("roleHolderNum", 0)
  },
  noNext: true,
  nextAfterDelay: 10,
  nextSteps: ["addNextRoleHolder"]
}, {
  name: "addNextRoleHolder",
  beforeStep: ({ changeFieldValue, chatState }) => {
    changeFieldValue("currentStep", (chatState.roleHolderNum || 0) + 1)
    changeFieldValue("roleHolderNum", (chatState.roleHolderNum || 0) + 1)

    console.log(chatState.roleHolder)

    if (ROLE_OPTIONS.length === 0) {
      ROLE_OPTIONS.splice(0, 0, ...ALL_ROLE_OPTIONS)
      ALL_ROLE_OPTIONS.forEach(role => ROLE_OPTIONS.push(role))
    } else if ((chatState.roleHolderNum || 0) > 0) {
      const index = ROLE_OPTIONS.findIndex(r => r.value === chatState.roleHolder.role)
      ROLE_OPTIONS.splice(index, 1)
    }
    
    changeFieldValue("roleHolder", { idType: "rsa_id" })
  },
  nextAfterDelay: 10, 
  noNext: true,
  nextSteps: ["roleHolder"]
}, {
  name: "roleHolder",
  beforeStep: ({ changeFieldValue, chatState }) => {
    changeFieldValue("currentStep", (chatState.roleHolderNum || 1))
  },
  showBack: true,
  title: "Create a New Group",
  title2: "Role Holder's Details",
  fieldNames: ["roleHolder.idNumber"],
  nextSteps: ["fetchRoleHolder"]
}, {
  name: "fetchRoleHolder",
  beforeStep: async ({ changeFieldValue, chatState, apolloClient }) => {
    let result = await apolloClient.query({ query: gql`
      query GetClient($idNumber: String) {
          clients(filter: { idNumber: $idNumber }) {
            id
            firstNames
            surname
            idNumber
            email
            cellNumbers
            birthDate
          }
        }`, variables: { idNumber: chatState.roleHolder.idNumber }
    })
    let data = result.data.clients[result.data.clients.length - 1]
    if (!data) {
      await apolloClient.mutate({ mutation: CREATE_CLIENT, variables: { input: { idNumber: chatState.roleHolder.idNumber, acceptedTerms: true } } })
      await new Promise(resolve => setTimeout(resolve, 5000))
      result = await apolloClient.query({ query: gql`
        query GetClient($idNumber: String) {
            clients(filter: { idNumber: $idNumber }) {
              id
              firstNames
              surname
              idNumber
              email
              cellNumbers
              whatsAppNumber
              preferredLanguage
            }
          }`, variables: { idNumber: chatState.roleHolder.idNumber }, fetchPolicy: "network-only"
          
      })
      data = result.data.clients[result.data.clients.length - 1]
    }
    
    if (data) {
      changeFieldValue("roleHolder.firstNames", data.firstNames)
      changeFieldValue("roleHolder.surname", data.surname)
      changeFieldValue("roleHolder.cellNumber", data.cellNumbers && data.cellNumbers[0])
      changeFieldValue("roleHolder.whatsAppNumber", data.whatsAppNumber || (data.cellNumbers && data.cellNumbers[0]))
      changeFieldValue("roleHolder.email", data.email)
      changeFieldValue("roleHolder.preferredLanguage", data.language || "English")
      changeFieldValue("roleHolderId", data.id)
    }
  },
  title: "Create a New Group",
  title2: "Role Holder's Details",
  nextSteps: ["roleHolder2"],
  noNext: true,
  nextAfterDelay: 10
}, {
  name: "roleHolder2",
  title: "Create a New Group",
  title2: "Role Holder's Details",
  // TODO: Home Address
  fieldNames: ["roleHolder.role", "roleHolder.firstNames", "roleHolder.surname", "roleHolder.email", "roleHolder.cellNumber", "roleHolder.whatsAppNumber", "roleHolder.preferredLanguage", "roleHolder.signature", "roleHolder.idDocumentId", "roleHolder.selfieId"],
  nextSteps: ["updateRoleHolder"]
}, {
  name: "updateRoleHolder",
  title: "Create a New Group",
  title2: "Role Holder's Details",
  beforeStep: async ({ chatState, apolloClient }) => {
    const { cellNumber, signature, role, idDocumentId, selfieId, ...inputData } = chatState.roleHolder
    await apolloClient.mutate({ mutation: gql`
      mutation UpdateClient($id: String!, $input: UpdateClientInput!) {
        updateClient(id: $id, input: $input) {
          id
          firstNames
          surname
          idNumber
          email
          cellNumbers
          birthDate
        }
      }`, variables: { id: chatState.roleHolderId, input: { ...inputData, cellNumbers: [cellNumber]  } }
    })
    await apolloClient.mutate({ mutation: gql`
      mutation AddRoleHolderToGroup($groupId: ObjID, $roleHolder: GroupRoleHolderInput!) {
        addRoleHolderToGroup(groupId: $groupId, roleHolder: $roleHolder) {
          id
        }
      }
    `, variables: { groupId: chatState.groupId, roleHolder: { clientId: chatState.roleHolderId, role, signature, idDocumentId, selfieId } } })
  },
  // nextAfterDelay: 10,
  component: () => <center><h1>Role Holder Added</h1><p /></center>,
  nextButtonsInColumn: true,
  nextButtons: ({ roleHolderNum }) => roleHolderNum === 3 ? [{ label: "Done", nextSteps: ["groupAccount"] }] : [{ label: "Add Another Role Holder", nextSteps: ["addNextRoleHolder"], buttonProps: { inverted: true } }, { label: "Done", nextSteps: ["groupAccount"] }]
  //nextSteps:   ["groupAccount"] : ["addNextRoleHolder"]
}, {
  name: "groupAccount",
  title: "Create a New Group",
  title2: "Is this a Group Account?",
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("currentStep", 4)
  },
  nextButtonsInColumn: true,
  nextButtons: [{ label: "Yes", nextSteps: ["populateAccountHolderInfo"] }, { label: "No", nextSteps: ["accountHolderInfo"] }],
  showBack: true
},
{
  name: "populateAccountHolderInfo",
  beforeStep: ({ changeFieldValue, chatState }) => {
    changeFieldValue("bankAccount.accountHolderName", chatState.name)
    changeFieldValue("bankAccount.accountHolderContactNumber", chatState.roleHolder.cellNumber)
    changeFieldValue("bankAccount.accountHolderEmail", chatState.roleHolder.email)
    changeFieldValue("bankAccount.accountHolderIdNumber", chatState.roleHolder.idNumber)
  },
  noNext: true,
  nextAfterDelay: 10,
  nextSteps: ["accountHolderInfo"]
},
{
  name: "accountHolderInfo",
  title: "Create a New Group",
  title2: "Account Holder Details",
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("currentStep", 4)
  },
  fieldNames: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderEmail", "bankAccount.accountHolderIdNumber"],
  requiredFields: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderIdNumber"],
  labels: true,
  nextSteps: ["bankAccountInfo"],
  showBack: true
},

{
  name: "bankAccountInfo",
  title: "Create a New Group",
  title2: "Bank Details",
  fieldNames: ["bankAccount.bankName", "bankAccount.accountNo", "groupProduct.paymentDueDay"],
  requiredFields: ["bankAccount.bankName", "bankAccount.accountNo", "groupProduct.paymentDueDay"],
  labels: true,
  nextSteps: ({ bankAccount, signedIn }) => ["createBankAccount"],
  showBack: true
},
{
  name: "createBankAccount",
  beforeStep: async ({  changeFieldValue, chatState, apolloClient }) => {
    const { bankAccount, groupId } = chatState
    const { bankName, accountNo, accountHolderName, accountHolderContactNumber, accountHolderEmail, accountHolderIdNumber } = bankAccount
    const result = await apolloClient.mutate({ mutation: CREATE_BANK_ACCOUNT, variables: { input: { bankName, accountNo, accountHolderName, accountHolderContactNumber, accountHolderEmail, accountHolderIdNumber, groupId } } })
    changeFieldValue("paymentBankAccountId", result.data.createBankAccount.id)
  },
  noNext: true,
  nextAfterDelay: 10,
  nextSteps: ["groupAdded"]
},
{
  name: "groupProduct",
  title: "Create a New Group",
  title2: "Does this group have an existing policy with another insurer?",
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("currentStep", 5)
  },
  nextButtonsInColumn: true,
  nextButtons: [{ label: "Yes", nextSteps: ["preExistingGroup"], buttonProps: { inverted: true } }, { label: "No", nextSteps: ["defaultCover"] }],
}, {
  name: "preExistingGroup",
  beforeStep: ({ changeFieldValue }) => { 
    changeFieldValue("groupProduct.onboarding.preExistingGroup", true)
  },
  title: "Create a New Group",
  title2: "Existing Group Details",
  fieldNames: ["groupProduct.onboarding.previousInsurer", "groupProduct.onboarding.applicationReason", "groupProduct.onboarding.lastYearClaimsAmount", "groupProduct.onboarding.lastYearPremiumAmount"],
  nextSteps: ["defaultCover"]
}, {
  name: "defaultCover",
  title: "Create a New Group",
  title2: "Product Choices",
  fieldNames: ["groupProduct.productType", "groupProduct.defaultCover.coverAmount", "groupProduct.defaultCover.extendedCoverPercentage"],
  nextSteps: ["createGroupProduct"],
}, {
  name: "createGroupProduct",
  beforeStep: async ({ changeFieldValue, chatState, apolloClient }) => {
    const { groupProduct, groupId, paymentBankAccountId, groupProductId } = chatState
    const { defaultCover, onboarding, paymentDueDay, productType } = groupProduct
    const result = await apolloClient.mutate({ mutation: UPDATE_GROUP_PRODUCT, variables: { id: groupProductId, input: { groupId, defaultCover, onboarding, paymentDueDay, paymentBankAccountId, productType } } })
    changeFieldValue("groupProductId", result.data.updateGroupProduct.id)
    changeFieldValue("groupProductNumber", result.data.updateGroupProduct.groupProductNumber)
  },
  noNext: true,
  nextAfterDelay: 10,
  nextSteps: ["groupAdded"]
},
{
  name: "groupAdded",
  beforeStep: ({ changeFieldValue }) => {
    changeFieldValue("wizardSection", "Done")
  },
  component: ({ chatState }) => <center><h1>Done!</h1><p /><h2>Group {chatState.groupProductNumber} has been added</h2><p /></center>,
  nextTitle: "Done",
  nextSteps: ["done"]
},
{
  name: "done",
  beforeStep: ({ chatState }) => {
    setOpen(false);
    navigate('/group-funeral/' + chatState.groupProductId)
  }
}])

const ALL_ROLE_OPTIONS = [{ value: "CHAIRPERSON", label: "Chairperson" }, { value: "SECRETARY", label: "Secretary" }, { value: "TREASURER", label: "Treasurer" }]
let ROLE_OPTIONS = [{ value: "CHAIRPERSON", label: "Chairperson" }, { value: "SECRETARY", label: "Secretary" }, { value: "TREASURER", label: "Treasurer" }]

const fields = {
  name: {
    type: "text",
    label: "Group Name",
    validate: [isRequired],
    required: true
  },
  postalCode: {
    type: "text",
    label: "Postal Code",
    validate: [isRequired],
    required: true
  },
  roleHolder: {
      "firstNames": {
        type: "text",
        label: "First Name",
        validate: [isRequired],
        required: true
      },
      "surname": {
        type: "text",
        label: "Surname",
        validate: [isRequired],
        required: true
      },
      "idNumber": {
        type: "text",
        label: "ID Number",
        validate: [isRequired, isValidIdNumber],
        required: true
      },
      whatsAppNumber: {
        label: "WhatsApp Number",
        type: "whatsappOpt"
      },
      preferredLanguage: {
        label: "Preferred Language",
        type: "select",
        options: [{ value: "English", label: "English" },
          { value: "Afrikaans", label: "Afrikaans" },
          { value: "Zulu", label: "Zulu" },
          { value: "Xhosa", label: "Xhosa" },
          { value: "Northern Sotho", label: "Northern Sotho" },
          { value: "Tswana", label: "Tswana" },
          { value: "Sotho", label: "Sotho" },
          { value: "Tsonga", label: "Tsonga" },
          { value: "Swati", label: "Swati" },
          { value: "Venda", label: "Venda" },
          { value: "Ndebele", label: "Ndebele" }],
        validate: [isRequired]
      },
      "gender": {
        label: "Gender",
        type: "selector",
        options: [{ value: "MALE", label: "Male" }, { value: "FEMALE", label: "Female" }],
        validate: [isRequired]
      },
      "birthDate": {
        type: 'date', 
        label: 'Birth Date',
        validate: [isRequired, doesDOBMatchIdNumber],
      },
      "email": {
        type: "text",
        label: "Email",
        validate: [isRequired, isValidEmail],
        required: true
      },
      "cellNumber": {
        type: "text",
        label: "Cell Number",
        validate: [isRequired, isValidCellphoneNumber],
        required: true
      },
      "role": {
        type: "select",
        label: "Role",
        options: ROLE_OPTIONS,
        required: true,
        validate: [isRequired]
      },
      "signature": {
        type: "signature"
      },
      idDocumentId: {
        prompt: "Upload Identity Document",
        type: "fileupload"
      },
      selfieId: {
        prompt: "Upload Selfie",
        type: "fileupload"
      },
  },
  "groupProduct.productType": {
    type: "selector",
    label: "Product Type",
    options: [{ value: "GROUP_FUNERAL_BSSP", label: "BSSP" }, { value: "GROUP_FUNERAL_GFS", label: "GFS" }],
    required: true
  },
  "groupProduct.paymentDueDay": {
    label: "What date do you pay your premiums?",
    type: 'select',
    options: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    keyboard: 'numeric',
    maxLength: 2,
    validate: [isRequired, isNumber]
  },
  "groupProduct.defaultCover.coverAmount": {
    type: "slider",
    label: "Default Cover Amount",
    min: 3000,
    max: 15000,
    step: 1000,
    validate: [isRequired, isNumber]
  },
  "groupProduct.defaultCover.extendedCoverPercentage": {
    type: "selector",
    label: "Adult Dependant Cover Percentage",
    options: [{ value: 50, label: "50%" }, { value: 100, label: "100%" }],
    validate: [isRequired, isNumber]
  },
  "groupProduct.onboarding.lastYearClaimsAmount": {
    type: "text",
    label: "Last Year Claims Amount",
    validate: [isRequired, isNumber]
  },
  "groupProduct.onboarding.lastYearPremiumAmount": {
    type: "text",
    label: "Last Year Premium Amount",
    validate: [isRequired, isNumber]
  },
  "groupProduct.onboarding.previousInsurer": {
    label: "Who is your current insurer?",
    type: 'radiogroup',
    options: [
      { label: "Sanlam", value: "Sanlam" },
      { label: "Old Mutual", value: "Old Mutual" },
      { label: "Metropolitan", value: "Metropolitan" },
      { label: "African Life", value: "African Life" }
    ],
    includeOther: true,
    otherField: {
      type: 'text',
      label: "Current Insurer"
    },
    validate: [isRequired]
  },
  "groupProduct.onboarding.applicationReason": {
    label: "Why are you applying to Meerkat?",
    type: 'radiogroup',
    options: [
      { label: "Premium Increase from Current Insurer", value: "Premium Increase from Current Insurer" },
      { label: "Better Rates", value: "Better Rates" },
      { label: "Better Service", value: "Better Service" },
      { label: "Better Product Offering", value: "Better Product Offering" },
    ],
    includeOther: true,
    otherField: {
      type: 'text',
      label: "Reason"
    }
  },
  bankAccount: {
    bankName: { type: 'select', options: banks.map(b => b.label), label: 'Bank', required: true, validate: [isRequired] },
    accountNo: { type: 'text', label: 'Account Number', required: true, validate: [isRequired, isNumber] },
    ownAccount:{ type: 'yesno', label: "Policy holder's account" },
    accountHolderName: { type: 'text', label: 'Premium Payer Name', required: true, validate: [isRequired] },
    accountHolderContactNumber: { type: 'text', label: 'Contact Number', required: true, validate: [isRequired, isValidCellphoneNumber] },
    accountHolderEmail: { type: 'text', label: 'Contact Email', required: true, validate: [isRequired, isValidEmail] },
    accountHolderIdNumber: { type: 'text', label: 'Id Number', required: true, validate: [isRequired, isValidRsaIdNumber] }
  },
}

function NewGroupWizard({ open, setOpen, setShowSideBar, showSideBar }) {
  const { data, loading, error } = useQuery(MY_DETAILS)
  useEffect(() => {
    setShowSideBar && setShowSideBar(false)
  }, [open])
  let { me } = data || {}
  
  if (loading) { return <div /> }

  return <WizardModal
    name="creditLifeQQ"
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen)}
    initialValues={chatState => ({ currentStep: 0, wizardSection: "QQ", salesPersonId: me && me.id, groupProduct: { onboarding: { preExistingGroup: false }, defaultCover: { coverAmount: 10000, extendedCoverPercentage: 50 }, paymentDueDay: 1, productType: "GROUP_FUNERAL_BSSP" } })}
    fields={fields}
    renderBodyHeader={FuneralQuickQuoteHeader}
    renderContentContainer={QuickQuoteModalContainer}
    renderBodyFooter={FuneralQuickQuoteProgress}
  />
}

export default NewGroupWizard
