import React, { useContext } from 'react'
import ActivityIndicator from '../../generic/ActivityIndicator'
import {getDOBFromID, forMemberTypes, isValidIdNumber, isValidEmail, isValidCellphoneNumber, isValidAge, isValidDay, doesDOBMatchIdNumber, doesAgeAtSignupMatchDOB, doesAgeAtSignupMatchId, isRequired, isRequiredForForeignID, isRequiredForThirdPartyAccount, isInteger } from '../../../lib/validators'
import schema from './schema'
import FuneralQuote from '../FuneralQuote'
import gql from 'graphql-tag'
import WizardModal from '../../shared/WizardModal'
// import { Mandate } from '../../payments'
import  { banks, idTypes, memberTypes, relationshipTypes } from '../../../constants'
import moment from 'moment'
import Text from '../../generic/Text'
import firebase from 'gatsby-plugin-firebase'
import { useQuery, Query } from 'react-apollo'
import { printCurrency } from '../../../lib/utils'
import {  FuneralQuickQuoteProgressContainer, FirstPageBackground, BeneficiaryInfoBoxContainer, BeneficiaryInfoBoxImage, FuneralQuickQuoteHeaderContainer, FuneralQuickQuoteFooterContainer } from './FuneralQuickQuote.style'
import {
  MokuHeadImageContainer,
  MokuHeadImage,
  WizardModalContentsContainer
} from '../../shared/WizardModal/WizardModal.style'
import mokuHead from '../../../images/mokuHead.svg'
import largeBlob from '../../../images/largeBlob.svg'
import mokuSmall from '../../../images/mokuSmall.png'
import WhoToCover from '../WhoToCover'
import { ChatContext } from '../../../context'
// import Img from 'gatsby-image'
import { ProgressBarFull, ProgressBarDone } from '../../savings/ProgressBar/ProgressBar.style'
import { gaTrack, fbTrack } from '../../../lib/tracking'
import { FieldValue } from 'react-form-helper'
import { ADD_LEAD, buildAddLeadMutationInput } from '../../../lib/contactForm'
import Stepper from '../../generic/Stepper'
import { memorial, will } from '../../../images/funeral'
import DebitOrderMandate from '../../shared/DebitOrderMandate'
import { gotoFlowComponent } from '../../generic/ChatFlow'
import { useExperiment } from '../../../hooks'
import MokuNotePanel from '../../shared/MokuNotePanel'

const getDOBFromIdNumber = (idNumber) =>
  idNumber
    ? moment(
        `${parseInt(idNumber.substring(0, 2)) < 24 ? "20" : "19"}${idNumber.substring(0, 6)}`,
        "YYYYMMDD",
      ).valueOf()
    : null;

const getGenderFromIdNumber = (idNumber) => parseInt(idNumber.substring(6, 10)) >= 5000 ? "MALE" : "FEMALE"

const getAgeFromIdNumber = (idNumber) => {
  return moment().diff(moment(getDOBFromIdNumber(idNumber)), 'year')
}

const quoteVariablesFromChatState = ({ idNumber, ageSpouse, age, members, gender, spouse, income, children, coverAmount, totalSpouseCover }) => ({
  age: members && members[0] ? members[0].ageAtSignup || age : age,
  idNumber,
  ageSpouse,
  gender: members && members[0] ? members[0].gender || gender : gender,
  spouse: spouse,
  children: children,
  coverAmount: coverAmount,
  parents: members.filter(m => m.memberType === "PARENT" && m.ageAtSignup).map(m => ({ age: m.ageAtSignup, gender: m.gender })),
  extended: members.filter(m => m.memberType === "EXTENDED" && m.ageAtSignup && m.gender).map(m => ({ age: m.ageAtSignup, gender: m.gender })),
  income: members && members[0] ? members[0].income : income
})

const MAX_CHILDREN = 5
const MAX_PARENT = 4
const MAX_EXTENDED = 10

const ABC_EXPERIMENT_ID = "67QGvS_FQxOFB9aFrg_wkA"

const CREATE_POLICY = gql`
  mutation CreateSalesInteractionPolicyWithMembers($funeral: CreateFuneralInput!, $bankAccount: CreateBankAccountInput, $beneficiary: AddBeneficiaryInput, $members: [CreateMemberInput!], $salesInteraction: CreateSalesInteractionInput!) {
    createSalesInteractionPolicyWithMembers(funeral: $funeral, bankAccount: $bankAccount, members: $members, salesInteraction: $salesInteraction, beneficiary: $beneficiary) {
      payAtNumber
      policyNumber
      clientId
      debitOrderId
      id
    }
  }
`

const MY_DETAILS = gql`{
  myClient {
    id
    firstNames
    surname
    idNumber
    idType
    passportExpiresDate
    birthDate
    cellNumbers
    gender
    email
    income
  }
}`

const GET_QUOTE = gql`
  query GetQuote($idNumber: String,$age : Int!, $ageSpouse: Int, $gender: Gender!, $spouse: Boolean, $children: Boolean, $coverAmount: Int!, $parents: [ParentQuoteInput], $extended: [ParentQuoteInput], $income: Float)
  {
    getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender, spouse: $spouse, children: $children, coverAmount: $coverAmount, parents: $parents, extended: $extended, income: $income) {
      premium
      totalCover
    }
  }
`

const CREATE_SALES_INTERACTION = gql`
  mutation CreateSalesInteraction($input: CreateSalesInteractionInput!) {
    createSalesInteraction(input: $input) {
      id
    }
  }
`

const CREATE_CLIENT_DOCUMENT = gql`
  mutation CreateClientDocument($input: CreateClientDocumentInput!) {
    createClientDocument(input: $input) {
      id
    }
  }
`

export const BeneficiaryInfoBox = ({ description, image, onClick }) => {
  return <BeneficiaryInfoBoxContainer onClick={onClick}>
    {image && <BeneficiaryInfoBoxImage src={image} alt={description} />}
    <Text size={16}>{description}</Text>
  </BeneficiaryInfoBoxContainer>
}

export const PencilText = (props) => {
 console.log(props.style)
 return <Text {...props} style={{ ...(props.style || {}), fontFamily: "Nanum Pen Script, cursive"}} />
}

const sanitizeMembers = (members) => {
  console.log('sanitizeMembers ', members)
  const coveredMembers = members.filter(d => d.memberType).map(member => member.idNumber && member.idType === "rsa_id" ? 
      ({ ...member, DOB: moment(getDOBFromID(member.idNumber, member.idType)).valueOf() }) : 
      ({ ...member, DOB: member.DOB && moment(member.DOB).valueOf() }))
  
  console.log('sanitizeMembers after ', coveredMembers)

  coveredMembers.forEach(member => {
   
    if (member.relationship && member.memberType === "PARENT") {
      if (member.relationship.includes('MOTHER')) {
        member.gender = "FEMALE"
      }
      else {
        member.gender = "MALE"
      }
    }

    if (member.hasOwnProperty('isCovered')) {
      delete member.isCovered
    }
    if (member.hasOwnProperty('isActive')) {
      delete member.isActive
    }
  })

  console.log('sanitizeMembers after ', coveredMembers)
  return coveredMembers
}

export function MokuTitle({ title, title2 }) {
  return <div style={{ width: "100%", textAlign: "center", marginBottom: "32px" }}>
    {title && <Text size={13} style={{ paddingLeft: "14px", paddingRight: "14px" }}>{title}</Text>}
    {title2 && <>{title && <div style={{ height: "8px" }} />}<Text size={26} bold style={{ paddingLeft: "14px", paddingRight: "14px" }}>{title2}</Text></>}
  </div>
}

function PricePerMonth({ chatState }) {
  const variables = quoteVariablesFromChatState(chatState)

  console.log("VARIABLES=", variables)

  return <Query query={GET_QUOTE} variables={variables}>
    {({ loading, error, data }) => <center>
        {loading ? <ActivityIndicator /> : <Text size={32} primary bold>{data && data.getPolicyQuote && printCurrency(data.getPolicyQuote.premium)}</Text>}
        <Text>per month</Text>
    </center>}
  </Query>
}

function stepsFromSchema(setOpen, variant) {
  const steps = [
    {
      name: "start",
      nextAfterDelay: 10,
      noNext: true,
      nextSteps: variant === '1' ? ["policyHolderIdNumber"] : variant === '2' ? ["policyHolderAgeGender"] : ["policyHolderInfo"]
    }, 
    /*{
      name: "logStart",
      text: <div style={{ paddingBottom: "28px"}}>
        <MokuTitle title2="Hi I'm Moku" />
        <center>
        <p />
        <Text size={24} style={{ width: "50%", textAlign: "center" }}>I'm here to quote on your funeral cover</Text>
        </center>
      </div>,
      fieldNames: ["acceptTerms"],
      beforeStep: () => {
        firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_START", {});
        fbTrack('track', 'InitiateCheckout')
        gaTrack('event', 'conversion', { 'send_to': 'AW-793318143/WHFLCObs7-gBEP-lpPoC' })
      },
      nextTitle: "Lets get started",
      nextSteps: ["policyHolderInfo"],
    },
    {
      name: "whoToCover",
      title: "Who would you like to cover?",
      beforeStep: ({ setChatState }) => {
        firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_COVER", {})
        setChatState({ started: true })
      },
      component: WhoToCover,
      // noNext: true,
      nextSteps: ["gender"]
    },*/
    {
      name: "policyHolderInfo",
      beforeStep: ({ changeFieldValue }) => {
        changeFieldValue('experimentVariant', "Original")
        changeFieldValue('currentStep', 0)
        firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_START", {});
        gaTrack('event', 'experiment_impression', {
          experiment_id: ABC_EXPERIMENT_ID,
          variant_id: `${ABC_EXPERIMENT_ID}.${variant || "0"}`
        })
      },
      fieldNames: ["firstName", "surname", "email", "age", "gender", "income"],
      requiredFields: ["firstName", "surname", "email", "age", "gender", "income"],
      fieldEvents: ["FUNERAL_QUICK_QUOTE_START_FIRST_NAME", "FUNERAL_QUICK_QUOTE_START_SURNAME", "FUNERAL_QUICK_QUOTE_START_EMAIL", "FUNERAL_QUICK_QUOTE_START_AGE", "FUNERAL_QUICK_QUOTE_START_GENDER", "FUNERAL_QUICK_QUOTE_START_INCOME"],
      title: "Quick Quote Funeral Cover",
      title2: "Let's get to know you better",
      nextTitle: "Get Quote",
      nextSteps: ["doQuote"]
    },
    {
      name: "policyHolderIdNumber",
      beforeStep: ({ changeFieldValue }) => {
        changeFieldValue('experimentVariant', "ID Number")
        changeFieldValue('currentStep', 0)
        firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_START", {});
        gaTrack('event', 'experiment_impression', {
          experiment_id: ABC_EXPERIMENT_ID,
          variant_id: `${ABC_EXPERIMENT_ID}.${variant || "1"}`
        })
      },
      fieldNames: ["idNumber"],
      requiredFields: ["idNumber"],
      fieldEvents: ["FUNERAL_QUICK_QUOTE_START_ID_NUMBER"],
      title: "Quick Quote Funeral Cover",
      title2: "Let's get to know you better",
      component: () => <><p /><MokuNotePanel title="Why your ID Number?" description="By providing us with your ID number we can generate a more personalised and accurate quote." color="grey" /></>,
      nextButtonsInColumn: true,
      nextButtons: [{ label: "Get Quote", nextSteps: ["doQuote"] }, { label: "I'd rather not", nextSteps: ["policyHolderAgeGender"], buttonProps: { inverted: true, noBorder: true } }],
      //nextTitle: "Get Quote",
      //nextSteps: ["doQuote"]
    },
    {
      name: "policyHolderAgeGender",
      beforeStep: ({ changeFieldValue, chatState }) => {
        if (!chatState.experimentVariant) {
          changeFieldValue('experimentVariant', "Age Gender")
          gaTrack('event', 'experiment_impression', {
            experiment_id: ABC_EXPERIMENT_ID,
            variant_id: `${ABC_EXPERIMENT_ID}.${variant || "2"}`
          })
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_START", {});
        } else {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_AGE_GENDER", {});
        }
        changeFieldValue('currentStep', 0)
      },
      fieldNames: ["age", "gender"],
      requiredFields: ["age", "gender"],
      fieldEvents: ["FUNERAL_QUICK_QUOTE_START_AGE", "FUNERAL_QUICK_QUOTE_START_GENDER"],
      title: "Quick Quote Funeral Cover",
      title2: "Let's get to know you better",
      title3: "Simply enter your age and gender and we'll provide an indictive quote for your funeral cover",
      nextTitle: "Get Quote",
      nextSteps: ["doQuote"]
    },
    {
      name: "spouseInfo",
      beforeStep: ({ changeFieldValue }) => {
        firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_SPOUSE_INFO", {});
      },
      fieldNames: ["ageSpouse"],
      title: "Quick Quote Funeral Cover",
      title2: "Let's get to know your spouse better",
      nextTitle: "Get Quote",
      nextSteps: ["doQuote"],
      handleNext: ({ values, gotoNext, setChatState }) => {
        if (values.ageSpouse) {
          setChatState({ spouse: true })
        }
        gotoNext()
      },
      showBack: true
    },
    /*{
      title: "Let's get to know you better",
      beforeStep: () => firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_YOUR_GENDER", {}),
      name: "gender",
      labels: true,
      skipStep: ({ gender }) => !!gender,
      fieldNames: ["gender"],
      nextSteps: ["age"]
    },
    {
      title: "Let's get to know you better",
      beforeStep: () => firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_YOUR_AGE", {}),
      labels: true,
      name: "age",
      skipStep: ({ age }) => !!age,
      fieldNames: ["age"],
      nextSteps: ["smoker"]
    },
    {
      title: "Let's get to know you better",
      title2: "Do you smoke?",
      beforeStep: () => firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_YOUR_SMOKER_STATUS", {}),
      labels: false,
      name: "smoker",
      skipStep: ({ smoker }) => smoker === true || smoker === false,
      fieldNames: ["smoker"],
      nextSteps: ["income"]
    },
    {
      title: "Let's get to know you better",
      beforeStep: () => firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_YOUR_INCOME", {}),
      labels: true,
      name: "income",
      fieldNames:["income"],
      nextSteps: chatState => chatState.spouse ? ["ageSpouse"] : chatState.parents ? ["parentQuoteInfo"] : chatState.extended ? ["extendedQuoteInfo"] : ["doQuote"],
      nextTitle: chatState => chatState.spouse || chatState.parents || chatState.extended ? "Next" : "Request quick quote",
    },
    {
      title: "Let's get to know your family",
      title2: "Your Spouse",
      labels: true,
      beforeStep: ({ chatState }) => {
        if (chatState.quoted) {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_SPOUSE_AGE", {})
        } else {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_ADD_SPOUSE_AGE", {})
        }
      },
      name: "ageSpouse",
      fieldNames: ["ageSpouse"],
      nextSteps: ["smokerSpouse"]
    },
    {
      title: "Let's get to know your family",
      title2: "Your Spouse",
      title3: "Do they smoke?",
      beforeStep: ({ chatState }) => {
        if (chatState.quoted) {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_SPOUSE_SMOKER_STATUS", {})
        } else {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_ADD_SPOUSE_SMOKER_STATUS", {})
        }
      },
      labels: true,
      name: "smokerSpouse",
      fieldNames: ["smokerSpouse"],
      nextSteps: ["incomeSpouse"]
    },
    {
      title: "Let's get to know your family",
      title2: "Your Spouse",
      beforeStep: ({ chatState }) => {
        if (chatState.quoted) {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_SPOUSE_INCOME", {})
        } else {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_ADD_SPOUSE_INCOME", {})
        }
      },
      labels: true,
      name: "incomeSpouse",
      fieldNames: ["incomeSpouse"],
      nextTitle: ({ quoted, parents, extended }) => quoted || (!extended && !parents) ? "Request quick quote" : "Next",
      nextSteps: ({ quoted, parents, extended }) => quoted ? ["doQuote"] : parents ? ["parentQuoteInfo"] : extended ? ["extendedQuoteInfo"] : ["doQuote"]
    },*/
    {
      name: "parentQuoteInfo",
      title: "Funeral Quick Quote",
      title2: "Let's get to know your parents better",
      title3: "We only need the ages of those you would like to cover at this stage",
      beforeStep: ({ chatState }) => {
        if (chatState.quoted) {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_PARENTS", {})
        } else {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_ADD_PARENTS", {})
        }
      },
      fieldNames: ["parentQuoteInfo[0].age", "parentQuoteInfo[1].age", "parentQuoteInfo[2].age", "parentQuoteInfo[3].age"],
      labels: true,
      nextSteps: ({ quoted, extended }) => quoted ? ["doQuote"] : extended ? ["extendedQuoteInfo"] : ["doQuote"],
      handleNext: ({ values, gotoNext, setChatState }) => {
        console.log("VALUES=", values)
        let parentSet = false
        const fields = ["parentQuoteInfo[0].age", "parentQuoteInfo[1].age", "parentQuoteInfo[2].age", "parentQuoteInfo[3].age"]
        fields.forEach(field => {
          if (values[field]) {
            parentSet = true
          }
        })
        if (parentSet) {
          setChatState({ parents: true })
        }
        gotoNext()
      },
      showBack: true
    },
    {
      name: "extendedQuoteInfo",
      title: "Funeral Quick Quote",
      title2: "Let's get to know your family better",
      beforeStep: ({ chatState }) => {
        if (chatState.quoted) {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_EXTENDED_NUMBER", {})
        } else {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_ADD_EXTENDED_NUMBER", {})
        }
      },
      fieldNames: ["numExtended"],
      nextSteps: ["extendedQuoteInfo2"],
      showBack: true
    },
    {
      name: "extendedQuoteInfo2",
      title: "Funeral Quick Quote",
      title2: "Let's get to know your family better",
      title3: "We only need the ages of those you would like to cover at this stage",
      beforeStep: ({ chatState }) => {
        if (chatState.quoted) {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_EXTENDED_AGE_GENDER", {})
        } else {
          firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE_ADD_EXTENDED_AGE_GENDER", {})
        }
      },
      skipStep: ({ numExtended }) => !numExtended,
      fieldNames: ({ numExtended }) => {
        var extendedFieldNames = []
        for (var i=0; i<numExtended; i++) {
          extendedFieldNames.push(`extendedQuoteInfo[${i}].age`)
          extendedFieldNames.push(`extendedQuoteInfo[${i}].gender`)
        }
        return extendedFieldNames
      },
      requiredFields: ({ numExtended }) => {
        var extendedFieldNames = []
        for (var i=0; i<numExtended; i++) {
          extendedFieldNames.push(`extendedQuoteInfo[${i}].age`)
          extendedFieldNames.push(`extendedQuoteInfo[${i}].gender`)
        }
        return extendedFieldNames
      },
      nextTitle: "Request quick quote",
      nextSteps: ["doQuote"],
      handleNext: ({ values, chatState, gotoNext, setChatState, ...args }) => {
        var hasExtended = false
        var extendedFieldNames = []
        for (var i=0; i<chatState.numExtended; i++) {
          extendedFieldNames.push(`extendedQuoteInfo[${i}].age`)
        }
        extendedFieldNames.forEach(field => {
          if (values[field]) {
            hasExtended = true
          }
        })
        if (hasExtended) {
          setChatState({ extended: true })
        }
        gotoNext()
      },
      showBack: true
    },
    {
      name: "doQuote",
      beforeStep: ({ changeFieldValue, setChatState, chatState }) => {
        if (chatState.idNumber) {
          changeFieldValue("gender", getGenderFromIdNumber(chatState.idNumber))
          changeFieldValue("age", getAgeFromIdNumber(chatState.idNumber))
        }
        changeFieldValue("steps", [
          { text: "Personal Details" },
          { text: "Quick Quote" }
        ])
        changeFieldValue("wizardSection", "QQ")
        changeFieldValue('currentStep', 1)
        setChatState({ quoted: true, numParents: chatState.parentQuoteInfo ? chatState.parentQuoteInfo.filter(p => p.age).length : 0 })
        firebase.analytics().logEvent("FUNERAL_QUICK_QUOTE", {})
        gaTrack('event', 'funeral_quick_quote')
      },
      component: FuneralQuote,
      noNext: true,
      showBack: true
    },
    {
      text: <>
        <MokuTitle title2="Not for you?" />
        <center><Text size={21}>Let us know why. We&apos;re always on the lookout to improve our products</Text></center>
        <p/ >
      </>,
      name: "noThanks",
      fieldNames: ["notInterestedReason"],
      beforeStep: () =>  {
        firebase.analytics().logEvent("QUICK_QUOTE_REJECT", {});
        fbTrack('track', 'Search')
      },
      nextTitle: "Submit",
      nextSteps: ["emailFeedback"],
    },
    {
      title2: "Thanks for your feedback! We would like to keep in touch",
      nextTitle: "Submit and take me Home",
      name: "emailFeedback",
      beforeStep: () => firebase.analytics().logEvent("QUICK_QUOTE_REJECT_REQUEST_EMAIL", {}),
      fieldNames: ["email"],
      // Create email lead
    }, {
      name: "mainMember",
      beforeStep: ({ changeFieldValue, chatState }) => {
        if (chatState.wizardSection === "QQ") {
          firebase.analytics().logEvent("NEW_FUNERAL_QUOTE_ACCEPT", {})
          fbTrack('track', 'CompleteRegistration')
          gaTrack('event', 'new_funeral_quote_accept')
          changeFieldValue("members", [{
            ...((chatState.members && chatState.members[0]) || {}),
            memberType: "POLICY_HOLDER",
            ageAtSignup: chatState.age,
            firstNames: chatState.firstName,
            surname: chatState.surname,
            // cellNumbers: chatState.cellNumber,
            gender: chatState.gender,
            income: chatState.income,
            email: chatState.email
          }])
          changeFieldValue("steps", [
            { text: "Personal Details" },
            { text: "Lives Covered" },
            { text: "Beneficiaries" },
            { text: "Payment Details" },
            { text: "Upload Documents" }
          ])
          changeFieldValue("wizardSection", "Buy")
        }
        changeFieldValue("currentStep", 0)
      },
      title: "Lets complete your profile",
      fieldNames: ['members[0].firstNames', 'members[0].surname', 'members[0].ageAtSignup', 'members[0].cellNumbers', 'members[0].email', 'members[0].DOB', 'members[0].idType', 'members[0].idNumber', 'members[0].income'],
      requiredFields: ['members[0].firstNames', 'members[0].surname', 'members[0].ageAtSignup', 'members[0].cellNumbers', 'members[0].email', 'members[0].DOB', 'members[0].idType', 'members[0].idNumber', 'members[0].income'],
      // Smoker? Product Question
      labels: true,
      nextSteps: ["spouse"],
      showBack: true
    }, {
      name: "spouse",
      beforeStep: ({ changeFieldValue, chatState }) => {
        changeFieldValue("members[1]", {
          ageAtSignup: chatState.ageSpouse,
          ...chatState.members[1],
          memberType: "SPOUSE"
        })
        changeFieldValue("currentStep", 1)
      },
      title: "Now for your spouse's profile",
      fieldNames: ['members[1].firstNames', 'members[1].surname', 'members[1].ageAtSignup', 'members[1].cellNumbers', 'members[1].email', 'members[1].DOB','members[1].idType', 'members[1].idNumber'],
      requiredFields: ['members[1].firstNames', 'members[1].surname', 'members[1].ageAtSignup', 'members[1].cellNumbers', 'members[1].DOB'],
      skipStep: ({ spouse }) => !spouse,
      labels: true,
      nextSteps: ["addChild1"],
      showBack: true
    },
    // Add in the steps for 5 children
    ...Array.from(Array(MAX_CHILDREN), (_, index) => ({
      name: `addChild${index + 1}`,
      beforeStep: ({ changeFieldValue, chatState }) => {
        changeFieldValue(`members[${index + (chatState.spouse ? 2 : 1)}]`, {
          ...chatState.members[index + (chatState.spouse ? 2 : 1)],
          memberType: "CHILD"
        })
        changeFieldValue('numChildren', index + 1)
        changeFieldValue("currentStep", 1)
      },
      labels: true,
      skipStep: ({ children }) => !children,
      text: <>
        <MokuTitle title="Now for your children" />
        <center><Text size={12}>Your policy provides cover for up to 5 children</Text></center>
        <p />
        <Text size={14} bold>Child {index + 1}</Text>
        <p />
      </>,
      fieldNames: ({ spouse  }) => [`members[${(spouse ? 2 : 1)+index}].firstNames`, `members[${(spouse ? 2 : 1)+index}].surname`, `members[${(spouse ? 2 : 1)+index}].ageAtSignup`, `members[${(spouse ? 2 : 1)+index}].DOB`, `members[${(spouse ? 2 : 1)+index}].idType`, `members[${(spouse ? 2 : 1)+index}].idNumber`],
      requiredFields: ({ spouse  }) => [`members[${(spouse ? 2 : 1)+index}].firstNames`, `members[${(spouse ? 2 : 1)+index}].surname`, `members[${(spouse ? 2 : 1)+index}].ageAtSignup`],
      nextButtonsInColumn: true,
      nextButtons: index < MAX_CHILDREN - 1 ? [{ label: "Add Another", nextSteps: [`addChild${index + 2}`] }, { label: "Done", nextSteps: ["addParent1"] }] : null,
      nextTitle: "Done",
      nextSteps: ["addParent1"],
      showBack: true
    })),
    ...Array.from(Array(MAX_PARENT), (_, index) => ({
      name: `addParent${index + 1}`,
      beforeStep: ({ changeFieldValue, chatState }) => {
        const quoteInfo = (chatState.parentQuoteInfo.filter(p => p.age)[index] || {})
        changeFieldValue(`members[${index + (chatState.spouse ? 2 : 1) + chatState.numChildren}]`, {
          ...quoteInfo,
          ageAtSignup: quoteInfo.age,
          ...chatState.members[index + (chatState.spouse ? 2 : 1) + chatState.numChildren],
          memberType: "PARENT"
        })
        changeFieldValue("currentStep", 1)
      },
      skipStep: ({ parents }) => !parents,
      // Perhaps use renderField?
      text: ({ numChildren }) => <>
        <MokuTitle title="Now for your parents" />
        <p />
        <Text size={14} bold><FieldValue mode="display" name={`members[${2+numChildren+index}].relationship`} /></Text>
        <p />
      </>,
      fieldNames: ({ numChildren, spouse }) => [`members[${(spouse ? 2 : 1)+numChildren+index}].firstNames`, `members[${(spouse ? 2 : 1)+numChildren+index}].surname`, `members[${(spouse ? 2 : 1)+numChildren+index}].ageAtSignup`, `members[${(spouse ? 2 : 1)+numChildren+index}].DOB`, `members[${(spouse ? 2 : 1)+numChildren+index}].idType`, `members[${(spouse ? 2 : 1)+numChildren+index}].idNumber`],
      requiredFields: ({ numChildren, spouse }) => [`members[${(spouse ? 2 : 1)+numChildren+index}].firstNames`, `members[${(spouse ? 2 : 1)+numChildren+index}].surname`, `members[${(spouse ? 2 : 1)+numChildren+index}].ageAtSignup`, `members[${(spouse ? 2 : 1)+numChildren+index}].DOB`],
      nextSteps: ({ numParents }) => index + 1 < numParents ? [`addParent${index + 2}`] : ["addExtended1"],
      showBack: true
    })),
    ...Array.from(Array(MAX_EXTENDED), (_, index) => ({
      name: `addExtended${index + 1}`,
      beforeStep: ({ changeFieldValue, chatState }) => {
        console.log("EXTENDED CHAT STATE", chatState, index + (chatState.spouse ? 2 : 1) + chatState.numChildren + chatState.numParents)
        const quoteInfo = (chatState.extendedQuoteInfo.filter(p => p.age && p.gender)[index] || {})
        changeFieldValue(`members[${index + (chatState.spouse ? 2 : 1) + chatState.numChildren + chatState.numParents}]`, {
          ...quoteInfo,
          ageAtSignup: quoteInfo.age,
          ...chatState.members[index + (chatState.spouse ? 2 : 1) + chatState.numChildren + chatState.numParents],
          memberType: "EXTENDED"
        })
        changeFieldValue("currentStep", 1)
      },
      labels: true,
      skipStep: ({ extended }) => !extended,
      text: <>
        <MokuTitle title="Now for your extended family" />
        <p />
        <Text size={14} bold>Extended Family Member {index + 1}</Text>
        <p />
      </>,
      fieldNames: ({ numChildren, numParents, spouse }) => [`members[${(spouse ? 2 : 1)+numChildren+numParents+index}].firstNames`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].surname`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].ageAtSignup`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].gender`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].relationship`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].DOB`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].idType`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].idNumber`],
      requiredFields: ({ numChildren, numParents, spouse }) => [`members[${(spouse ? 2 : 1)+numChildren+numParents+index}].firstNames`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].surname`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].ageAtSignup`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].gender`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].relationship`, `members[${(spouse ? 2 : 1)+numChildren+numParents+index}].DOB`],
      nextSteps: ({ numExtended }) => index + 1 < numExtended ? [`addExtended${index + 2}`] : ["beneficiary"],
      handleNext: ({ gotoNext, changeFieldValue, chatState }) => {
        changeFieldValue('numExtended', parseInt(chatState.numExtended))
        gotoNext()
      },
      showBack: true
    })), {
      name: "beneficiary",
      beforeStep: ({ changeFieldValue }) => {
        changeFieldValue("currentStep", 2)
      },
      title: "Buy Funeral Cover",
      title2: "Why we need you to add a Beneficiary",
      component: ({ gotoNext }) => <>
        <BeneficiaryInfoBox description="As the policyholder, you will receive the payouts should anything happen to the people you've covered" image={will} onClick={gotoNext} />
        <BeneficiaryInfoBox description="You can nominate someone to receive the payout in the event of your death" image={memorial} onClick={gotoNext} />
      </>,
      showBack: true,
      nextSteps: ["beneficiarySpouse"]
    },
    {
      beforeStep: ({ changeFieldValue }) => {
        changeFieldValue("currentStep", 2)
      },
      name: "beneficiarySpouse",
      skipStep: ({ spouse }) => !spouse,
      title2: "Is your spouse the beneficiary?",
      fieldNames: ["beneficiary.isSpouse"],
      labels: true,
      noNext: true,
      nextOnValue: true,
      nextSteps: ({ beneficiary }) => beneficiary && beneficiary.isSpouse === "Y" ? ["ownAccount"] : ["beneficiaryInfo"],
      showBack: true,
    },
    {
      beforeStep: ({ changeFieldValue }) => {
        changeFieldValue("currentStep", 2)
      },
      name: "beneficiaryInfo",
      title2: "Beneficiary Details",
      fieldNames: ["beneficiary.firstNames", "beneficiary.surname", "beneficiary.cellNumbers", "beneficiary.email", "beneficiary.idType", "beneficiary.idNumber", /* "beneficiary.bankName", "beneficiary.accountNo" */],
      requiredFields: ["beneficiary.firstNames", "beneficiary.surname", "beneficiary.cellNumbers"],
      labels: true,
      showBack: true,
      nextSteps: ["ownAccount"]
    },
    {
      beforeStep: ({ changeFieldValue }) => {
        changeFieldValue("currentStep", 3)
      },
      name: "ownAccount",
      text: chatState => <>
        <MokuTitle title2="Will you be paying for this Funeral Policy?" />
        <p />
        <PricePerMonth chatState={chatState} />
        <p />
      </>,
      noNext: true,
      fieldNames: ["bankAccount.ownAccount"],
      nextOnValue: true,
      showBack: true,
      nextSteps: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? ["bankAccountInfo"] : ["accountHolderInfo"]
    },
    {
      name: "accountHolderInfo",
      title: "Account Holder Details",
      beforeStep: ({ changeFieldValue }) => {
        firebase.analytics().logEvent("NEW_FUNERAL_ACCOUNT_HOLDER_INFO", {});
        changeFieldValue("currentStep", 3)
      },
      fieldNames: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderEmail", "bankAccount.accountHolderIdNumber"],
      requiredFields: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderIdNumber"],
      labels: true,
      nextSteps: ["bankAccountInfo"],
      showBack: true
    },
    {
      name: "bankAccountInfo",
      beforeStep: ({ changeFieldValue }) => {
        firebase.analytics().logEvent("NEW_FUNERAL_BANK_ACCOUNT_INFO", {});
        gaTrack('event', 'new_funeral_bank_account_info')
        changeFieldValue("currentStep", 3)
      },
      text: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? <MokuTitle title="Please provide your bank account details to collect premiums from" /> : <MokuTitle title="Account Holder Info" title2="Bank Account" />,
      fieldNames: ["bankAccount.bankName", "bankAccount.accountNo", "funeral.paymentDueDay"],
      requiredFields: ["bankAccount.bankName", "bankAccount.accountNo", "funeral.paymentDueDay"],
      labels: true,
      nextSteps: ({ bankAccount, signedIn }) => bankAccount.ownAccount === "Y" ? ["completeSale"] : ["doMandateSent"],
      showBack: true
    },
    {
      name: "completeSale",
      beforeStep: async ({ apolloClient, chatState, setChatState }) => {
        console.log('Completing sale ...', chatState.bankAccount )
        console.log('Completing sale members...', chatState.members )
        const { data } = await apolloClient.mutate({ mutation: CREATE_POLICY, variables: {
          funeral: { paymentMethod:"DEBI_CHECK", coverAmount: chatState.coverAmount || 30000, ...chatState.funeral },
          bankAccount: chatState.bankAccount ? { ...chatState.bankAccount, bankName: chatState.bankAccount.bankName ?chatState.bankAccount.bankName :"Capitec Bank", ownAccount: chatState.bankAccount.ownAccount === "Y" } : null,
          members: chatState.members && sanitizeMembers(chatState.members),
          beneficiary: chatState.beneficiary ? { ...chatState.beneficiary, isSpouse: chatState.beneficiary.isSpouse === "Y" } : { isSpouse: true },
          salesInteraction: {}
        } });
        console.log(data)
        if (data) {
          fbTrack('track', 'Lead')
          firebase.analytics().logEvent("NEW_FUNERAL_PURCHASE_COMPLETE", { policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber })
          gaTrack('event', 'new_funeral_purchase_complete')
          setChatState({ policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber, bankAccountId: data.createSalesInteractionPolicyWithMembers.paymentBankAccountId, clientId: data.createSalesInteractionPolicyWithMembers.clientId, debitOrderId: data.createSalesInteractionPolicyWithMembers.debitOrderId })
        }
      },
      nextAfterDelay: 1000,
      noNext: true,
      nextSteps: ["doMandate"]
    },
    {
      name: "doMandate",
      component: gotoFlowComponent(DebitOrderMandate, ({ chatState, ...args }) => { console.log(chatState, args); return {  id: chatState.debitOrderId, startWithCleanState: false, noCloseButton: true } }),
      noNext: true,
      nextSteps: ["getDocuments"]
    },
    {
      name: "getDocuments",
      beforeStep: ({ changeFieldValue }) => {
        changeFieldValue("currentStep", 4)
      },
      title: "Upload Policy Holder Documents",
      title2: "Providing these documents can help speed things up at claim stage",
      fieldNames: ["selfieId", "idCardId"],
      nextSteps: ({ signedIn }) => [`completedSale${signedIn ? "SignedIn" : ""}`],
      component: () => <div style={{ height: "90px"}} />,
      nextButtonsInColumn: true,
      nextButtons: [{ label: "Done", nextSteps: ["uploadDocs"] }, { label: "Skip for now", buttonProps: { inverted: true, outlined: false } }]
    },
    {
      name: "uploadDocs",
      beforeStep: async ({ apolloClient, chatState }) => {
        const { selfieId, idCardId, clientId } = chatState
        if (selfieId) {
          await apolloClient.mutate({ mutation: CREATE_CLIENT_DOCUMENT, variables: { input: { clientId, name: "Selfie", source: "FUNERAL_QUICK_QUOTE", type: "SELFIE", fileId: selfieId } } })
        }
        if (idCardId) {
          await apolloClient.mutate({ mutation: CREATE_CLIENT_DOCUMENT, variables: { input: { clientId, name: "ID Document", source: "FUNERAL_QUICK_QUOTE", type: "RSA_ID", fileId: idCardId } } })
        }
      },
      nextSteps: ({ signedIn }) => [`completedSale${signedIn ? "SignedIn" : ""}`],
      nextAfterDelay: 1000,
      noNext: true
    },
    {
      name: "completedSale",
      beforeStep: ({ changeFieldValue }) => {
        changeFieldValue("wizardSection", "Done")
      },
      // component: SignDebitOrderMandate,
      text: ({ policyNumber, bankAccount }) => <>
        <div style={{ position: "absolute", left: "calc(79px + calc((100% - 360px) / 2)" }}>
          <PencilText primary size={34} style={{ marginBottom: "20px", height: "30px", lineHeight: "30px", transform: "rotate(-7.69deg)" }}>Nearly!</PencilText>
          <div style={{ marginLeft: "40px" }}><PencilText primary size={28} style={{ width: "13px", height: "30px", transform: "rotate(90deg)", lineHeight: "30px" }}>{"<"}</PencilText></div>
        </div>
        <div style={{ height: "18px"}} />
        <MokuTitle title2="We've &nbsp; got you covered!" />
        <center>
        <Text size={16} style={{ padding: "14px" }}>{bankAccount && bankAccount.ownAccount === "Y" ? "Your cover will be active as soon as the first payment is received." : "Your cover will be active as soon as the first payment is received."}</Text>
        <p /> 
        <Text bold size={16} style={{ padding: "14px" }}>Your policy number is {policyNumber}</Text>
        <p />
        </center>
      </>,
      nextButtonsInColumn: true,
      nextButtons: [{ label: "Finish, take me home", nextSteps: ["done"], buttonProps: { inverted: true } }, { label: "Register to view policy", nextSteps: ["signup"] }],
      // DO Mandate
    }, {
      name: "completedSaleSignedIn",
      beforeStep: ({ changeFieldValue }) => {
        changeFieldValue("wizardSection", "Done")
      },
      /*beforeStep: async ({ apolloClient, chatState, setChatState }) => {
        console.log('Completing sale ...', chatState.bankAccount )
        console.log('Completing sale members...', chatState.members )
        const { data } = await apolloClient.mutate({ mutation: CREATE_POLICY, variables: {
          funeral: { paymentMethod:"DEBI_CHECK", coverAmount: chatState.coverAmount, ...chatState.funeral },
          bankAccount: chatState.bankAccount ? { ...chatState.bankAccount, bankName: chatState.bankAccount.bankName ?chatState.bankAccount.bankName :"Capitec Bank", ownAccount: chatState.bankAccount.ownAccount === "Y" } : null,
          members: chatState.members && sanitizeMembers(chatState.members),
          beneficiary: chatState.beneficiary ? { ...chatState.beneficiary, isSpouse: chatState.beneficiary.isSpouse === "Y" } : { isSpouse: true },
          salesInteraction: {}
        } });
        console.log(data)
        if (data) {
          fbTrack('track', 'Lead')
          firebase.analytics().logEvent("NEW_FUNERAL_PURCHASE_COMPLETE", { policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber })
          setChatState({ policyNumber: data.createSalesInteractionPolicyWithMembers.policyNumber })
        }
      },*/
      // component: SignDebitOrderMandate,
      text: ({ policyNumber, bankAccount }) => <>
        <div style={{ position: "absolute", left: "calc(79px + calc((100% - 360px) / 2)" }}>
          <PencilText primary size={34} style={{ marginBottom: "20px", height: "30px", lineHeight: "30px", transform: "rotate(-7.69deg)" }}>Nearly!</PencilText>
          <div style={{ marginLeft: "40px" }}><PencilText primary size={28} style={{ width: "13px", height: "30px", transform: "rotate(90deg)", lineHeight: "30px" }}>{"<"}</PencilText></div>
        </div>
        <div style={{ height: "18px"}} />
        <MokuTitle title2="We've &nbsp; got you covered!" />
        <center>
        <Text size={16} style={{ padding: "14px" }}>{bankAccount && bankAccount.ownAccount === "Y" ? "Your cover will be active as soon as the first payment is received." : "Your cover will be active as soon as the first payment is received."}</Text>
        <p /> 
        <Text bold size={16} style={{ padding: "14px" }}>Your policy number is {policyNumber}</Text>
        <p />
        </center>
      </>,
      nextTitle: "Finish",
      nextSteps: ["done"]
      // DO Mandate
    }, {
      name: "doMandateSent",
      text: <><MokuTitle title="We will send a debit order mandate to the account holder to sign." />
      <center><Text size={16} style={{ padding: "14px" }}>Once that has been signed, your policy will be issued and your cover will be active once the first payment has been received.</Text></center>
      <p />
      </>,
      nextTitle: "Send now",
      nextSteps: ({ signedIn }) => ["completeSale"]
    }, {
      name: "done",
      beforeStep: () => setOpen(false),
      noNext: true
    }, {
      name: "signup",
      beforeStep: async ({ chatState }) => {
        const { clientId } = chatState
        fbTrack('track', 'ViewContent')
        firebase.analytics().logEvent("NEW_FUNERAL_PURCHASE_REGISTER", {})
        window.location=`https://${process.env.HOSTNAME}/sign-in?redirect=/funeral&clientId=${clientId}`
      }
    }, {
      name: "callback",
      title: "Quick Quote Funeral Cover",
      title2: "Call Back Request",
      fieldNames: ["firstName", "surname", "cellNumber", "email"],
      beforeStep: async () => {
        firebase.analytics().logEvent("QUICK_QUOTE_CALLBACK_REQUEST", {})
      },
      nextSteps: ["sendCallback"],
      showBack: true
    }, {
      name: "sendCallback",
      beforeStep: async ({ apolloClient, chatState }) => {
        firebase.analytics().logEvent("QUICK_QUOTE_CALLBACK_REQUEST_SENT", {})
        apolloClient.mutate({ mutation: ADD_LEAD, variables: buildAddLeadMutationInput({
          firstName: chatState.firstName,
          surname: chatState.surname,
          cellNumber: chatState.cellNumber,
          email: chatState.email,
          talkAbout: "Funeral Cover",
          message: "Quick Quote Callback Request",
          routing: "fun"
        }) })
        setOpen(false)
      },
      noNext: true
    }]
  return steps
}

// Todo move to validators
const validateAgeRange = (age, min = 18, max = 60) => age && parseInt(age) >= min && parseInt(age) <= max

export const isValidAgeRange = (min = 18, max = 60) => value => (
  !value || validateAgeRange(value, min, max)
    ? undefined
    : `Age must be between ${min} and ${max}`
)

export const isNumberRange = (min = 18, max = 60) => value => (
  !value || validateAgeRange(value, min, max)
    ? undefined
    : `Must be between ${min} and ${max}`
)

// TODO: Gender options constant
function fieldsFromSchema() {
  const quizSchema = schema.quizSchema()

  const fields = {
    firstName: { type: 'text', label: "First Name", validate: [isRequired] },
    surname: { type: 'text', label: "Last Name", validate: [isRequired] },
    cellNumber: { type: 'number', label: 'Mobile Number', validate: [isRequired, isValidCellphoneNumber] },
    coverAmount: {
      label: "Estimated Cover Amount",
      type: 'slider',
      endText: null,
      startText: "R",
      min: 10000,
      step: 10000,
      max: 60000,
      validate: value => value > 0 && value <= 60000 ? undefined : /*'Cover Amount must be between 0 and 60 000'*/ [10000,20000,30000,40000,50000,60000].includes(value) ? undefined : 'Cover Amount must be 10000, 20000, 30000, 40000, 50000 or 60000'
    },
    idNumber: { type: 'number',label: "ID Number", maxLength: 13, validate:[isRequired, isValidIdNumber] },
    members: {
      type: 'fieldarray',
      config:
      {
        firstNames: { type: 'text', label: "First Name", validate: [isRequired] },
        surname: { type: 'text', label: "Last Name", validate: [isRequired] },
        gender: { type: "selector", label: "Gender", validate: [forMemberTypes(["EXTENDED", "PARENT"], isRequired)], options: [{ value: "MALE", label: "Male" }, { value: "FEMALE", label: "Female" }] },
        ageAtSignup: { type: 'text', label: "Age",
          validate: [ forMemberTypes(["PARENT", "SPOUSE", "CHILD", "EXTENDED"],isRequired), forMemberTypes(["CHILD"], isValidAgeRange(0, 17)), forMemberTypes(["SPOUSE", "POLICY_HOLDER"], isValidAgeRange()), forMemberTypes(["PARENT", "EXTENDED"], isValidAgeRange(18, 75)), isValidAge, doesAgeAtSignupMatchId, doesAgeAtSignupMatchDOB] },
        email: { type: 'email', label: 'Email', validate: [forMemberTypes(["POLICY_HOLDER"],isRequired), isValidEmail] },
        DOB: { type: 'date', label: 'Birth Date',
          validate: [forMemberTypes(["PARENT", "SPOUSE", "CHILD", "EXTENDED"],isRequired), doesDOBMatchIdNumber, isValidAge] },
        idNumber: { type: 'number',label: "ID Number", maxLength: 13,
          validate:[forMemberTypes(["POLICY_HOLDER"],isRequired), isValidIdNumber] },
        idType: { type: "select",options: idTypes,label: "Identity Type",
          validate:[forMemberTypes(["POLICY_HOLDER"],isRequired)] },
        passportExpiresDate: { type: "date", label: "Passport Expiry Date",
          /*validate:[isRequiredForForeignID] */},
        memberType: { type: "select",options: memberTypes,label: "Member Type"},
        relationship: {
          type: "select",
          options: relationshipTypes.filter(
            (r) =>
              r.value.indexOf("MOTHER") === -1 &&
              r.value.indexOf("FATHER") === -1 &&
              r.value.indexOf("FRIEND") === -1 &&
              r.value.indexOf("FAMILY_FRIEND") === -1 &&
              r.value.indexOf("SPOUSE") === -1 &&
              r.value.indexOf("HUSBAND") === -1 &&
              r.value.indexOf("WIFE") === -1
          ),
          label: "Relationship",
          validate: [forMemberTypes(["EXTENDED", "PARENT"], isRequired)],
        },
        cellNumbers: { type: 'number', label: "Cell Number", maxLength: 10,
          validate: [forMemberTypes(["POLICY_HOLDER","SPOUSE"],isRequired), isValidCellphoneNumber] },
        income: { type: "currency", label: "Gross Income", validate: [forMemberTypes(["POLICY_HOLDER"], isRequired)] },
        smoker: { label: "How often do you smoke?", type: "radiogroup", options: [{ value: false, label: "0 cigarettes a week"}, { value: true, label: "1-10 cigarettes a week" }, { value: true, label: "More than 10 cigarettes a week" }] }
      }
    },
    beneficiary: {
      isSpouse:{type: 'yesno', column: true, label: "Is your spouse the beneficiary" },
      firstNames: { type: 'text', label: "Beneficiary First Name ", validate: [isRequired] },
      surname: { type: 'text', label: "Beneficiary Last Name *", validate: [isRequired] },
      cellNumbers: { type: 'number', label: "Beneficiary Cell Number *", maxLength: 10, validate: [isRequired, isValidCellphoneNumber] },
      email: { type: 'email', label: 'Beneficiary Email', validate: [isValidEmail] },
      idNumber: { type: 'text',label: "Beneficiary ID Number", maxLength: 13,validate:[isValidIdNumber] },
      idType: { type: "select",options: idTypes,label: "Beneficiary Identity Type"},
      passportExpiresDate: { type: "select",options: idTypes,label: "Passport Expiry Date"},
      bankName: { type: 'select', options: banks, label: 'Beneficiary Bank' },
      accountNo: { type: 'text', label: 'Beneficiary Account Number' }
    },
    bankAccount: {
      bankName: { type: 'select', options: banks.map(b => b.label), label: 'Bank', validate: [isRequired] },
      accountNo: { type: 'text', label: 'Account Number', validate: [isRequired] },
      ownAccount:{ type: 'yesno', column: true, label: "Policy holder's account" },
      accountHolderName: { type: 'text', label: 'Premium Payer Name', validate: [isRequiredForThirdPartyAccount] },
      accountHolderContactNumber: { type: 'text', label: 'Contact Number', validate: [isRequiredForThirdPartyAccount, isValidCellphoneNumber]},
      accountHolderEmail: { type: 'text', label: 'Contact Email', validate: [isValidEmail] },
      accountHolderIdNumber: { type: 'text', label: 'Id Number', validate: [isRequiredForThirdPartyAccount] }
    },
    funeral: {
      paymentDueDay: {
        label: "When do you get paid?",
        type: 'radiogroup',
        options: [
          { label: "1st of the Month", value: 1 },
          { label: "15th of the Month", value: 15 },
          { label: "20th of the Month", value: 20 },
          { label: "25th of the Month", value: 25 },
          { label: "Last Day of the Month", value: 31 },
        ],
        includeOther: true,
        otherField: {
          type: 'select',
          options: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
          label: "Select Payment Day"
        },
        keyboard: 'numeric',
        maxLength: 2
      },
    },
    idCardId: {
      prompt: "Upload your Identity Document",
      type: "fileupload"
    },
    selfieId: {
      prompt: "Upload your Selfie",
      type: "fileupload"
    },
    age: {
      label: "Your Age",
      type: "slider",
      min: 18,
      max: 60,
      step: 1,
      endText: "years",
      startText: null,
      validate: [isRequired, isValidAgeRange()]
    },
    income: {
      label: "Your Gross Income",
      type: "slider",
      min: 0,
      max: 50000,
      step: 100,
      validate: [isRequired]
    },
    incomeSpouse: {
      label: "Their Gross Income",
      type: "slider",
      min: 0,
      max: 50000,
      step: 100,
      validate: [isRequired]
    },
    ageSpouse: {
      label: "Their Age",
      type: "slider",
      endText: "years",
      startText: null,
      min: 18,
      max: 60,
      step: 1,
      validate: [isRequired, isValidAgeRange()]
    },
    gender: {
      label: "Your Gender",
      type: "selector",
      options: [{ value: "MALE", label: "Male" }, { value: "FEMALE", label: "Female" }],
      validate: [isRequired]
    },
    smoker: {
      type: "radiogroup",
      options: [{ value: true, label: "Yes, I do" }, { value: false, label: "No, I don't" }],
      validate: [isRequired]
    },
    smokerSpouse: {
      type: "radiogroup",
      options: [{ value: true, label: "Yes, they do" }, { value: false, label: "No, they don't" }],
      validate: [isRequired]
    },
    notInterestedReason: {
      type: "radiogroup",
      options: ["I have a cheaper policy", "Don't need cover just yet", "Just shopping around", "I need more information first"]
    },
    email: {
      label: "Enter email",
      validate: [isValidEmail, isRequired]
    },
    acceptTerms: {
      type: "checkbox",
      label: "Accept our T&Cs",
      validate: value => value ? undefined : "Need to accept Ts and Cs to Continue"
    },
    "parentQuoteInfo[0].age": {
      label: "Mother's Age",
      validate: [isValidAgeRange(18, 75)]
    },
    "parentQuoteInfo[1].age": {
      label: "Father's Age",
      validate: [isValidAgeRange(18, 75)]
    },
    "parentQuoteInfo[2].age": {
      label: "Mother in law's Age",
      validate: [isValidAgeRange(18, 75)]
    },
    "parentQuoteInfo[3].age": {
      label: "Father in law's Age",
      validate: [isValidAgeRange(18, 75)]
    },
    extendedQuoteInfo: {
      type: "fieldarray",
      config: {
        age: {
          label: "Age",
          validate: [isRequired, isValidAgeRange(18, 75)]
        },
        gender: {
          label: "Gender",
          type: "radiogroup",
          options: [{ value: "MALE", label: "Male" }, { value: "FEMALE", label: "Female" }],
          validate: [isRequired]
        }
      }
    },
    numExtended: {
      label: "How many extended family members?",
      validate: [isRequired, isInteger, isNumberRange(1, MAX_EXTENDED)]
    }
  }

  return fields
}

const newFuneralSteps = (setOpen, variant) => stepsFromSchema(setOpen, variant)
const fields = fieldsFromSchema(schema)

function MokuImage() {
  const { chatState } = useContext(ChatContext)

  /*const data = useStaticQuery(graphql`
    query {
      moku: file(relativePath: { eq: "mokuSmall.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)*/
  
  if (chatState && !chatState.started) {
    return <img src={mokuSmall} alt="Moku" /> /* (
      <>
        <p />
        <Img fixed={data.moku.childImageSharp.fixed} />
      </>
    )*/
  } else {
    return (
      <div /> )
      /*<MokuHeadImageContainer>      
        <MokuHeadImage src={mokuHead} alt="Moku" />
      </MokuHeadImageContainer>*/
    }
}

export function FuneralQuickQuoteHeader({ goBack, currentSteps }) {
  const { chatState } = useContext(ChatContext)

  console.log(chatState)
  const { currentStep = 0, steps = [{ text: ""} , { text: "" }], wizardSection = "QQ" } = chatState

  if (wizardSection === "QQ" || wizardSection === "Done") {
    return <div />
  }

  return <FuneralQuickQuoteHeaderContainer>
    <Stepper stepData={steps} currentStep={currentStep} />
  </FuneralQuickQuoteHeaderContainer>
}

export function QuickQuoteModalContainer({ children }) {
  const { chatState } = useContext(ChatContext)

  return <WizardModalContentsContainer>
    {/*chatState && !chatState.started && <FirstPageBackground src={largeBlob} alt="Funeral Insurance Quick Quote" />*/}
    {children}
  </WizardModalContentsContainer>
}

const getPercentageDone = (chatState) => {
  const { age, gender, ageSpouse, spouse, income, incomeSpouse } = chatState

  if (spouse) {
    if (!gender) {
      return 10
    }
    if (!age) {
      return 28
    }
    if (!income) {
      return 46
    }
    if (!ageSpouse) {
      return 64
    }
    if (!incomeSpouse) {
      return 82
    }
  } else {
    if (!gender) {
      return 10
    }
    if (!age) {
      return 40
    }
    if (!income) {
      return 70
    }
  }
  return 100
}

export function FuneralQuickQuoteProgress() {
  const { chatState } = useContext(ChatContext)

  console.log(chatState)
  const { currentStep = 0, steps = [{ text: ""} , { text: "" }], wizardSection = "QQ" } = chatState

  if (wizardSection === "Done") {
    return <div />
  }

  return <FuneralQuickQuoteFooterContainer>
    <Stepper stepData={steps} currentStep={currentStep} isMobile />
  </FuneralQuickQuoteFooterContainer>
}

const getMemberInfoFromClient = (client) => {
  if (!client || !client.idNumber) {
    return {
      idType: "rsa_id"
    }
  }

  return {
    firstNames: client.firstNames,
    surname: client.surname,
    cellNumbers: client.cellNumbers,
    gender: client.gender,
    idNumber: client.idNumber,
    idType: client.idType,
    DOB: client.birthDate,
    ageAtSignup: client.birthDate ? moment().diff(moment(client.birthDate), "years") : null,
    passportExpiresDate: client.passportExpiresDate,
    email: client.email
  }
}

function FuneralQuickQuote({ open, setOpen }) {
  // if logged in then get the client details
  const experimentVariant = useExperiment(ABC_EXPERIMENT_ID)

  console.log("VARIANT=", experimentVariant, ABC_EXPERIMENT_ID)

  const { data, loading, error } = useQuery(MY_DETAILS)
  let { myClient } = data || {}
  if (!myClient) {
    myClient = {}
  }
  console.log("FUNERALQQ", data, loading, error, myClient)

  if (loading) { return <div /> }

  return (
    <WizardModal 
      name="newFuneral"
      steps={newFuneralSteps(setOpen, experimentVariant)}
      fields={fields}
      open={open}
      setOpen={setOpen}
      initialValues={chatState => ({ currentStep: 0, acceptTerms: true, signedIn: !!(myClient && myClient.idNumber), age: myClient && myClient.birthDate ? moment().diff(moment(myClient.birthDate), "years") : null, gender: (myClient && myClient.gender) || "MALE", quoted: false, started: true, spouse: false, parents: false, parentQuoteInfo: [{ gender: "FEMALE", relationship: "MOTHER" }, { gender: "MALE", relationship: "FATHER" }, { gender: "FEMALE", relationship: "MOTHER_IN_LAW" }, { gender: "MALE", relationship: "FATHER_IN_LAW" }], extended: false, coverAmount: 30000, firstName: myClient.firstNames, surname: myClient.surname, income: myClient.income, idNumber: myClient.idNumber, email: myClient.email, idType: myClient.idType, cellNumber: myClient.cellNumbers && myClient.cellNumbers[0], bankAccount: { bankName:"Capitec Bank", verifyAccountInfo: true  }, members: [getMemberInfoFromClient(myClient)], funeral: { paymentDueDay: 25 }, beneficiary: { idType: "rsa_id"}, numChildren: 0 })}
      renderBodyHeader={FuneralQuickQuoteHeader}
      renderContentContainer={QuickQuoteModalContainer}
      renderBodyFooter={FuneralQuickQuoteProgress}
    />
  )
}

export default FuneralQuickQuote