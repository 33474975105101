import styled from '@emotion/styled'

const QuickQuotePriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 328px;
`

export {
  QuickQuotePriceContainer
}
