import { ActivityIndicator, Button, Text } from "../components/generic"
import React, { useState } from "react"
import gql from 'graphql-tag'
import { useQuery } from "react-apollo"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { ThemeProvider } from "emotion-theming"
import { useTheme } from "./group-sales-sign-in"
import NewGroupWizard from "../components/group/NewGroupWizard"
import GroupsList from "../components/group/GroupsList/GroupsList"
// TODO: Combine with Sales

const GET_EMPLOYEE_INFO = gql`{
  me {
    id
    first
    last
  }
}`


function SalesPage() {

  const [open, setOpen] = useState(false)
  const { data, loading, error } = useQuery(GET_EMPLOYEE_INFO)

  if (loading) return <ThemeProvider theme={useTheme}><ActivityIndicator /></ThemeProvider>

  if (!data || !data.me) {
    return <Text>Not Authorised</Text>
  }

    return (
      <ThemeProvider theme={useTheme}>
      <Layout noHeader noMoku noFooter>
        <SEO title="Sales" noTemplate />
          <center>
          <p />
          <p />
          <NewGroupWizard open={open} setOpen={setOpen} />
          <img style={{ width: 150 }} src={useTheme.logo} alt="logo" />
          <Text size={32} bold>Group Sales</Text>
          <p />
          <p>
            <Text>Welcome {data.me.first} {data.me.last}</Text>
          </p>
          <p>
            <Button onClick={() => setOpen(true)}>New Group</Button>
          </p>
          <GroupsList />
          <p />
          <p>
            <Button inverted noBorder onClick={() => navigate('/logout?redirect=/group-sales-sign-in')}>Logout</Button>
          </p>
        </center>
        
        </Layout>
        </ThemeProvider>
    )
}


export default SalesPage