import styled from '@emotion/styled'

const Quote = styled.div`
  display: flex;
  justify-content: center;
  font-size:21px;
  font-weight:600
`

export {
  Quote
}
