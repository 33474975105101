import styled from '@emotion/styled'

const FuneralItemCardContainer = styled.div`
  background: ${props => props.theme.colors.grey};
  border-radius: 4px;
`

export {
  FuneralItemCardContainer
}
