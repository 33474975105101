import styled from '@emotion/styled'
import { device } from '../../../theme.style'

const BeneficiaryInfoBoxContainer = styled.div`
  text-align: center;

  width: 328px;
  height: 223px;

  /* Light Grey */

  background: #F9F8F8;
  /* InnerShadow_1 */

  box-shadow: inset 0px 0px 10px rgba(64, 67, 87, 0.08);
  border-radius: 4px;

  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;

  padding: 28px;
`

const BeneficiaryInfoBoxImage = styled.img`
  width: 84.76px;
  height: 84px;

  border-radius: 42px;
  border: 5.6px solid #26948F;

  margin: 0;
  margin-bottom: 17px;
`

const FuneralQuickQuoteProgressContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  width: calc(100% - 32px);
  flex-direction: row;
  height: 40px;
  margin: 16px;

`

const FirstPageBackground = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const FuneralQuickQuoteHeaderContainer = styled.div`
  ${device.tabletS`
    display: none;
  `}
`

const FuneralQuickQuoteFooterContainer = styled.div`
  display: none;

  ${device.tabletS`
    display: block;
  `}
`

export {
  FuneralQuickQuoteProgressContainer,
  FirstPageBackground,
  BeneficiaryInfoBoxContainer,
  BeneficiaryInfoBoxImage,
  FuneralQuickQuoteHeaderContainer,
  FuneralQuickQuoteFooterContainer
}