import React from 'react'
import { QuickQuotePriceContainer } from './QuickQuotePrice.style'
import Text from '../../generic/Text'

function QuickQuotePrice({ title, price, disclaimer, color }) {
  return <QuickQuotePriceContainer>
    <Text color={color} size={13}>{title}</Text>
    <div style={{ height: "15px" }} />
    <Text color={color} size={18} bold>{price}</Text>
    <div style={{ height: "5px" }} />
    <Text color={color} size={8}>{disclaimer}</Text>
  </QuickQuotePriceContainer>
}

export default QuickQuotePrice
