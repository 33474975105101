import React, { useState, useRef } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { FormField } from 'react-form-helper'
import ActivityIndicator from '../../generic/ActivityIndicator'
import Button from '../../generic/Button'
import Text from '../../generic/Text'
import { Row, Col } from '../../generic/grid'
import { Quote } from './FuneralQuote.style'
import { printCurrency } from '../../../lib/utils'
import WhoToCover from '../WhoToCover'
// import { navigate } from 'gatsby'
import InfoCard from '../../shared/InfoCard'
import MokuHoldingCard from '../../shared/MokuHoldingCard'
import QuickQuotePrice from '../QuickQuotePrice/QuickQuotePrice'
import MokuNotePanel from '../../shared/MokuNotePanel'

const QUOTE_AMOUNTS = [10000, 20000, 30000, 40000, 50000, 60000]

const DEFAULT_COVER_AMOUNT = 30000

const MAX_TOTAL_COVER_AMOUNT = 100000

const GET_QUOTE = gql`
  query GetQuote($idNumber: String,$age : Int!, $ageSpouse: Int, $gender: Gender!, $spouse: Boolean, $children: Boolean, $coverAmount: Int!, $parents: [ParentQuoteInput], $extended: [ParentQuoteInput],  $income: Float)
  {
    quote: getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender, spouse: $spouse, children: $children, coverAmount: $coverAmount, parents: $parents, extended: $extended, income: $income) {
      premium
      totalCover
    }
    withSpouse: getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender, spouse: true, children: $children, coverAmount: $coverAmount, parents: $parents, extended: $extended, income: $income) {
      premium
      totalCover
    }
    withChildren: getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender, spouse: $spouse, children: true, coverAmount: $coverAmount, parents: $parents, extended: $extended, income: $income) {
      premium
      totalCover
    }
    noParents: getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender, spouse: $spouse, children: $children, coverAmount: $coverAmount, extended: $extended, income: $income) {
      premium
      totalCover
    }
    noExtended: getPolicyQuote(idNumber: $idNumber, age: $age, ageSpouse: $ageSpouse, gender: $gender, spouse: $spouse, children: $children, coverAmount: $coverAmount, parents: $parents, income: $income) {
      premium
      totalCover
    }
  }
`

//TODO: Share this function
const quoteVariablesFromChatState = ({ idNumber, ageSpouse, age, members, gender, spouse, income, children, coverAmount, parentQuoteInfo, extendedQuoteInfo, totalSpouseCover }) => {
  const parents = (parentQuoteInfo || []).filter(p => p.age).map(p => ({ age: parseInt(p.age), gender: p.gender }))
  const extended = (extendedQuoteInfo || []).filter(p => p.age && p.gender).map(p => ({ age: parseInt(p.age), gender: p.gender }))
  const myAge = parseInt(members && members[0] ? members[0].ageAtSignup || age : age)

  return {
    age: myAge,
    idNumber,
    ageSpouse: ageSpouse ? parseInt(ageSpouse) : (gender === "MALE" ? myAge - 3 : myAge + 3),
    gender: members && members[0] ? members[0].gender || gender : gender,
    spouse,
    children,
    coverAmount: coverAmount || DEFAULT_COVER_AMOUNT,
    parents: parents.length === 0 ? [{ age: gender === "MALE" ? myAge + 17 : myAge + 20, gender: "FEMALE" }] : parents,
    extended: extended.length === 0 ? [{ age: gender === "MALE" ? myAge + 17 : myAge + 20, gender: "FEMALE" }] : extended,
    income: parseFloat((members && members[0]) ? members[0].income || income : income)
  }
}

const genderText = {
  'MALE': 'man',
  'FEMALE': 'woman'
}

const spouseGenderText = {
    'MALE': 'woman',
    'FEMALE': 'man'
  }

// TODO: aged and up to five children
function CoverDescription({ proposed, variables: { coverAmount, gender, age, spouse, children, ageSpouse, totalSpouseCover } }) {
  return (
    <Quote>
      { printCurrency(proposed, 0)}{' '} cover for a {genderText[gender]} aged {age}
      { spouse && children ? ", a " + spouseGenderText[gender] + " aged " + ageSpouse + "" : spouse  && " and " + spouseGenderText[gender] + " aged " + ageSpouse + "" }
      { children && " and children" }
    </Quote>
  )
}

function FuneralQuote({ step, chatState, setChatState, gotoNext }) {
  console.log("We Quoting Now .....")

  console.log('chatstate ', chatState)
  const [showNext, setShowNext] = useState(true)
  const quoteCard = useRef(null)

  const autoScroll = () => {
    setTimeout(() => quoteCard.current && quoteCard.current.scrollIntoView(true), 100)
  }

  const variables = quoteVariablesFromChatState(chatState)
  console.log('Funeral quote ', variables, chatState, QUOTE_AMOUNTS)

  return (
    <Query query={GET_QUOTE} variables={variables}>
      {
        ({ loading, error, data }) => {
          if (error) {
            console.log(error)
            return "An error has occured"
          }

          console.log(data)

          const quoteAddOns = {
            spouse: (data && data.withSpouse && data.withSpouse.premium) - (data && data.quote && data.quote.premium) || 0,
            children: (data && data.withChildren && data.withChildren.premium) - (data && data.quote && data.quote.premium) || 0,
            parents: (data && data.quote && data.quote.premium) - (data && data.noParents && data.noParents.premium) || 0,
            extended: (data && data.quote && data.quote.premium) - (data && data.noExtended && data.noExtended.premium) || 0
          }

          const { parents, extended }  = chatState
          
          console.log(quoteAddOns, "before")

          let price = data && (data.quote.premium - (parents ? 0 : quoteAddOns.parents) - (extended ? 0 : quoteAddOns.extended))
          console.log("price before", price)
          const priceDiff = Math.max(0, 45 - price)
          price += priceDiff
          quoteAddOns.children -= Math.min(priceDiff, quoteAddOns.children)
          quoteAddOns.spouse -= Math.min(priceDiff, quoteAddOns.spouse)
          quoteAddOns.parents -= Math.min(priceDiff, quoteAddOns.parents)
          quoteAddOns.extended -= Math.min(priceDiff, quoteAddOns.extended)

          console.log(quoteAddOns, price)

          autoScroll()
            return (
                <div ref={quoteCard}>
                  <center>
                    <MokuHoldingCard>
                      {loading ? <ActivityIndicator large="true"/> :
                      <QuickQuotePrice title="Meerkat Estimated Funeral Cover Quick Quote" disclaimer="This is an estimated premium and is subject to change." price={`${data && data.quote && printCurrency(price)}pm`} color="white" />}
                    </MokuHoldingCard>
                  </center>
                  <p />
                  <FormField name="coverAmount" inputProps={{ onChange: (e, v) => setChatState({ coverAmount: v }) }} />
                  <MokuNotePanel title="Moku Tip: Too much or too little cover?" description="To amend cover amount simply use the slider above. To add or remove dependents select them below." color="grey" />
                  <p />
                  <center>
                  <div style={{ width: "95%" }}>
                  <WhoToCover chatState={chatState} setChatState={setChatState} gotoNext={gotoNext} addOns={quoteAddOns} isQuote />
                  {showNext && (
                    <Col>
                      <Row>
                        <Col centerHorizontal><Button action="true"onClick={() => { setShowNext(false); gotoNext(["mainMember"]) }}>Get me covered!</Button></Col>
                      </Row>
                      <Row padTop="16px">
                        <Col centerHorizontal><Button inverted action="true"onClick={() => { setShowNext(false); gotoNext(["callback"]) }}>Request a Callback</Button></Col>
                      </Row>
                    </Col>
                  )}
                  </div>
                  </center>     
                </div>
                )
             }
    }
    </Query>
  )
}

FuneralQuote.QUOTE_AMOUNTS = QUOTE_AMOUNTS

export default FuneralQuote