import { sex, getAgeFromID, isRequired, isValidCellphoneNumber, isValidIdNumber } from '../../../lib/validators'
import  { idTypes } from '../../../constants'

// TODO - get this from the questions or split into sections
let lastQuestionKey

const transformPreQuoteQuestions = questions => {
  lastQuestionKey = questions && questions[questions.length - 1].id
  return (
    questions &&
    questions.map((question, index) => {
      return {
        question: question.question,
        elements: [{ name: `${question.id}`, type: 'yesno' }],
        showCondition: data => data[lastQuestionKey] == null || data[lastQuestionKey] == 'N',
        startIncrement: data => data[lastQuestionKey] != null,
        cardIconProps: {
          iconBackgroundColor: '#235789',
          icon: 'question'
        }
      }
    })
  )
}

const requiresSelectorsFor = ['id_type','idNumber','gender', 'ageSpouse', 'age', 'parents', 'income', 'spouse', 'parentsCover', 'children']

const validAge = data => {
  console.log("valid age values are ", data, data.age, data.spouse)
  if (!data.age) data.age = getAgeFromID(data.idNumber)
  console.log("valid age values are ", data, data.age, data.spouse)

  return (
    (data.spouse == 'N' && data.age >= 18 && data.age <= 60) ||
    (data.spouse == 'Y' && data.ageSpouse >= 18 && data.ageSpouse <= 60)

    /* Save this in case we bring back a Self question
    if (data.self === 'Y') {
      if (data.age == null || data.age < 18 || data.age > 60) {
        return false
      }
    }
    if (data.spouse === 'Y') {
      if (data.ageSpouse == null || data.ageSpouse < 18 || data.ageSpouse > 60) {
        return false
      }
    }
    if (data.self === 'N' && data.spouse === 'N' ){
      return false
    }
    return true
    */
   )
}

const validateAgeRange = (age, min = 18, max = 60) => age && age >= min && age <= max

const getGender = (data) => !!data.gender

const getAge = (data) => !!data.age /*{
  if (data.id_type === "rsa_id") {
    const age = getAgeFromID(data.idNumber)
    if (age) {
      data.age = age
    }
    return age
  }
  return undefined
}*/


const getElementFromObject = (element, name) => {
  const temp = name.split('.')
  if (temp.length == 1) {
    return element && element[name]
  }
  return getElementFromObject(element[temp[0]], temp.slice(1).join('.'))
}

const ageElement = (label, name, min = 18, max = 60) => ({
  label,
  name,
  type: 'number',
  keyboard: 'numeric',
  maxLength: 2,
  validate: value =>
    !value || value.length < 2 || validateAgeRange(value, min, max)
      ? undefined
      : `Age must be between ${min} and ${max}`
})

const readyForSubmit = data => {
console.log('ready ', data, validAge(data))
//data.payer &&
  //data.age = getAgeFromID(data.idNumber)
  return (
  data &&
  validAge(data) &&
  (!data.parentsCover || data.parentsCover == "N" || (data.parentsCover == 'Y' &&
    (data.parents &&
      Object.keys(data.parents).filter(key => data.parents[key].age >= 18 && data.parents[key].age <= 75).length > 0)))
)}

const quizSchema = questions => [
  //...transformPreQuoteQuestions(questions),
  //data[lastQuestionKey] == 'Y'
  /*{
    question: 'What is your name?',
    elements: [{ name: 'firstName', type: 'text' }],
    showCondition: data => true,
    startIncrement: data => data.done != "Y",
    cardIconProps: {
      iconBackgroundColor: '#508991',
      icon: 'question'
    }
  },
  {
    question: 'What is your Cell Number?',
    elements: [{
      name: 'cellNumber', type: 'text',
      validate: isValidCellphoneNumber
    }],
    showCondition: data => true,
    startIncrement: data => data.done != "Y",
    cardIconProps: {
      iconBackgroundColor: '#508991',
      icon: 'question'
    }
  },
  {
    question: 'What ID do you have?',
    elements: [
      {
        name: 'idType',
        type: "select",
        options: idTypes,
        validate:isRequired
    }],
    showCondition: data => true,
    startIncrement: data => data.done != "Y",
    cardIconProps: {
      iconBackgroundColor: '#508991',
      icon: 'question'
    }
  },
  {
    question: 'What is your ID Number?',
    elements: [{
      name: 'idNumber',
      type: 'text',
      validate: isValidIdNumber
    }],
    showCondition: data => true,
    startIncrement: data => data.spouse == "Y" || data.spouse == "N",
    cardIconProps: {
      iconBackgroundColor: '#508991',
      icon: 'question'
    }
  },*/
  {
    question: 'Do you want your spouse / partner covered?',
    elements: [{ name: 'spouse', type: 'yesno' }],
    showCondition: data => true ,
    startIncrement: data => data.spouse != null,
    cardIconProps: {
      iconBackgroundColor: '#508991',
      icon: 'male-female'
    }
  },
  {
    question: 'Do you want your children covered (up to five)?',
    elements: [{ name: 'children', type: 'yesno' }],
    showCondition: data => data.spouse != null,
    startIncrement: data => data.children != null,
    answerIncrement: 3,
    cardIconProps: {
      iconBackgroundColor: '#508991',
      icon: 'family'
    }
  },
  {
    question: 'If affordable, would you like your parents covered?',
    elements: [{ name: 'parentsCover', type: 'yesno' }],
    showCondition: data => false,
    startIncrement: data => data.parentsCover != null,
    cardIconProps: {
      iconBackgroundColor: '#00CC9B',
      icon: 'male-female'
    }
  },
  {
    question: 'What is your gender?',
    elements: [
      {
        name: 'gender',
        type: 'options',
        options: [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }]
      }
    ],
    showCondition: data => !getGender(data) && data.children != null,
    answerIncrement: 4,
    cardIconProps: {
      iconBackgroundColor: '#235789',
      icon: 'question'
    }
  },
  {
    question: 'What is your age?',
    placeholder: '18 to 60 years',
    elements: [ageElement(null, 'age')],
    answerIncrement: 3,
    showCondition: data => !getAge(data) && data.gender != null,
    cardIconProps: data => ({
      iconBackgroundColor: data.gender == 'MALE' ? '#005988' : '#EF2D56',
      icon: data.gender == 'MALE' ? 'male' : 'female'
    })
  },
  {
    question: "What is your spouse / partner's age?",
    placeholder: '18 to 60 years',
    elements: [ageElement(null, 'ageSpouse')],
    answerIncrement: 2,
    showCondition: data => data.spouse == 'Y',
    cardIconProps: data => ({
      iconBackgroundColor: data.gender == 'MALE' ? '#EF2D56' : '#005988',
      icon: data.gender == 'MALE' ? 'female' : 'male'
    })
  },
  {
    question: 'What are the ages of the parents you want to cover?',
    placeholder: '18 to 75 years',
    elements: [
      ageElement('Mother', 'parents.mother.age', 18, 75),
      ageElement('Father', 'parents.father.age', 18, 75),
      ageElement('Mother In Law', 'parents.motheril.age', 18, 75),
      ageElement('Father In Law', 'parents.fatheril.age', 18, 75)
    ],
    showCondition: data => data.parentsCover == 'Y' && validAge(data),
    cardIconProps: {
      iconBackgroundColor: '#00CC9B',
      icon: 'male-female'
    }
  },
  /*{
    question: 'Will you be paying the premium?',
    elements: [{ name: 'payer', type: 'yesno' }],
    showCondition: data => 1 == 2 && data.age != null && (data.spouse == 'N' || (data.ageSpouse && data.ageSpouse.length >1)),
    cardIconProps: {
      iconBackgroundColor: '#508991',
      icon: 'male-female'
    }
  },*/
  {
    question: 'How much do you earn a month?',
    elements: [{ name: 'income', type: 'currency', maxLength: 6 }],
    showCondition: data => readyForSubmit(data),
    cardIconProps: {
      iconBackgroundColor: '#508991',
      icon: 'money'
    }
  }
  /*,
  {
    question: 'How much would you like to pay per month?',
    elements: [{ name: 'currentPremium', type: 'number', maxLength: 4, normalize: onlyNums }],
    showCondition: data => readyForSubmit(data),
    cardIconProps: {
      iconBackgroundColor: '#F2CB52',
      icon: 'money'
    }
  }
  */
]

const getLastQuestionKey = () => "income"

export default {
  quizSchema,
  getLastQuestionKey,
  requiresSelectorsFor,
  readyForSubmit
}
