import React from 'react'
import Auth from '../components/Auth'
import { ActivityIndicator } from '../components/generic'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import theme from '../theme.style'
import { ThemeProvider } from 'emotion-theming'

export const useTheme = theme


function SignInPage() {
  if (typeof window === 'undefined') {
    return  <ThemeProvider theme={useTheme}>
      <ActivityIndicator />
    </ThemeProvider>
  }

  return <ThemeProvider theme={useTheme}>
    <Layout noHeader noMoku noFooter>
      <SEO title="Salesperson Sign In" noTemplate />
      <Auth redirect="/group-sales" azureAuth login />
    </Layout>
  </ThemeProvider>
}

export default SignInPage